<template>
  <v-container>
    <v-row class="align-center justify-center">
      <!--<v-col cols="12" class="rdsForm">
       <!- <iframe  src="https://freegame.probrain.com.br/cta"></iframe> -->
        <!-- <div role="main" id="forms-pagina-de-jogos-fa19eb362e596b77267e">
          {{ createRdForm() }}
        </div> -->
        <!-- <script type="application/javascript"> {{new RDStationForms('forms-pagina-de-jogos-fa19eb362e596b77267e', 'UA-163644675-3').createForm()}}</script> -->
        <!-- <v-img class="cursor" contain   @click="goTo(url)" :src="require(`../assets/${img}`)"></v-img> -->
   <!--   </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CTA",
  props: {
    url: String,
    img: String,
  },
  methods: {
   
    goTo: function (link) {
      console.log(link);
      window.location.href = link;
    },
  },
  // mounted(){
  //   this.createRdForm()

  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .rdsForm{
    position: relative;
   
   /* margin-top: 25px;
    margin-bottom: 25px;
    height: 500px;
    background-color: #735023 !important; */

  }

  /*
  .rdsForm iframe {
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 500px !important;
    height: 100%;
    border: none;
    background-color: #735023 !important;
    
  }
  
@media (max-width: 768px) {
  .rdsForm iframe{
  
    width: 100% !important;
   
    
    
  }
}
*/

</style>
