<template>
  <div>
    <div
      :class="$vuetify.breakpoint.xs ? 'site_banner_mobile' : 'site_banner'"
    ></div>
    <div class="site_banner2"></div>

    <v-container>
      <v-col
        cols="12"
        :class="$vuetify.breakpoint.xs ? 'text-center' : 'text-center mt-3'"
      >
        <button>
          <v-img
            class="audiofoco-logo"
            :width="$vuetify.breakpoint.xs ? '200' : '280'"
            @click="goTo('https://www.afinandocerebro.com.br/')"
            src="../assets/audiofocologo.png"
          ></v-img>
        </button>
      </v-col>
      <v-col cols="12">
        <v-row
          :class="
            $vuetify.breakpoint.xs
              ? 'align-center justify-center mt-10'
              : 'align-center justify-center mt-16'
          "
        >
          <h1 class="title_text white--text">TRABALHE A SUA ESCUTA</h1>
        </v-row>
      </v-col>

      <v-card
        elevation="16"
        :class="
          $vuetify.breakpoint.xs
            ? 't-gradient-1 '
            : 'ml-10 mr-10 t-gradient-1 mt-5'
        "
      >
        <v-row no-gutters class="align-center justify-center pb-8 pt-8">
          <v-col cols="12" class="text-center">
            <GameFrame
              url="http://freegame.probrain.com.br/exports/localizacao"
              size="70vh"
              sizem="90vh"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="align-center justify-center"
          style="background-color: #087283"
        >
          <v-col cols="10" class="text-center">
            <CTA
              url="https://novo.afinandoocerebro.com.br/login/cadastro"
              img="destaque2.jpg"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import GameFrame from "./GameFrame.vue";
import CTA from "./CTA.vue";
import Footer from "./FooterModule1.vue";

export default {
  name: "App",
  components: {
    GameFrame,
    CTA,
    Footer,
  },
  methods: {
    goTo: function (link) {
      console.log(link);
      window.location.href = link;
    },
  },
};
</script>

<style>
@keyframes scroll {
  100% {
    background-position: 100px -500px;
  }
}

.title_text {
  font-size: 2.5rem;
  z-index: 2 !important ;
}

.site_banner {
  background: no-repeat top center fixed;
  background-image: url("../assets/old_lady_no_gradient.jpg");
  opacity: 0.8;
  background-size: contain;
  z-index: 0 !important;
  position: absolute;
  width: 100%;
  height: 30%;
}

.site_banner_mobile {
  background-image: url("../assets/old_lady_no_gradient.jpg");
  opacity: 0.8;
  background-size: cover;
  z-index: 0 !important;
  position: absolute;
  width: 100%;
  height: 30%;
}
.site_banner2 {
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 35%,
    rgba(255, 255, 255, 0.8) 90%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 35%,
    rgba(255, 255, 255, 0.8) 90%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 35%,
    rgba(255, 255, 255, 0.8) 90%
  );
  background-size: contain;
  position: absolute;
  width: 100%;

  height: 30%;
}

.t-gradient-1 {
  background: rgb(160, 215, 225);
  background: -moz-linear-gradient(
    360deg,
    rgb(255, 255, 255) 0%,
    rgb(250, 250, 250) 100%
  );
  background: -webkit-linear-gradient(
    360deg,
    rgb(255, 255, 255) 0%,
    rgb(250, 250, 250) 100%
  );
  background: linear-gradient(
    360deg,
    rgb(255, 255, 255) 0%,
    rgb(250, 250, 250) 100%
  );
}

.audiofoco-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
</style>
