<template>
  <v-main>
    <div class="bg"></div>
    <v-container class="justify-center">
      <v-card elevation="6" class="g_page ml-8 mr-8 g-gradient-1">
        <!-- -->
        <v-row no-gutters class="align-center justify-center">
          <!--   <v-col cols="12" class="text-center">

            <button>
              <v-img
                class="afinando-logo"
                :width="$vuetify.breakpoint.xs ? '100' : '140'"
                @click="goTo('https://www.afinandocerebro.com.br/')"
                src="../assets/btn_bg.png"
              ></v-img>
            </button>
          </v-col>
          -->
          <!--   <div class="ctawrap"> -->

          <v-col class="text-center ">
            <div class="wrapper">
              <v-img src="../assets/bricks2.jpg" width="100%" height="100%"> </v-img>

              <!--  <v-img src="../assets/circulo_imagem.png"  class="elementos zindex"></v-img>
              <v-img src="../assets/circulo_logo.png" class="elementos zindex"></v-img>
              <v-img src="../assets/cta/menina_no_pc.png" class="elementos"></v-img>
              <v-img src="../assets/cta/circulo_menina_do_celular.png" class="elementos"></v-img>
              <v-img src="../assets/cta/menina_no_celular.png" class="elementos"></v-img>
              <v-img src="../assets/cta/controle.png" class="elementos move"></v-img>
              <v-img src="../assets/cta/coracao_01.png" class="elementos move"></v-img>
              <v-img src="../assets/cta/coracao_02.png" class="elementos move"></v-img>
              <v-img src="../assets/cta/cordas.png" class="elementos"> </v-img>
              <v-img src="../assets/cta/texto1.png" class="elementos"> </v-img>
              <v-img src="../assets/cta/seta.png" class="elementos move"></v-img> -->
              <v-img 
                src="../assets/Banner_v2_freegame4.png"
                class="elementos register"
                @click="goTo('https://novo.afinandoocerebro.com.br/login/cadastro')">
              </v-img>

            </div>
          </v-col>
        <!--  <div class="texto">
                <h1 class="title1">GOSTOU DESSE JOGO?</h1>
                <h1 class="title2">
                  Tenha acesso a muito mais!<br />
                  Faça seu cadastro gratuitamente<br />
                  no <span>Afinando</span>!
                </h1>
              </div> -->

        
         
           <!--   <v-col cols="12" class="text-center headerArts">
             <div class="wrapper">
                <v-img alt="afinando header"  class="header-art" src="../assets/header_art_bg.png"></v-img>
                <v-img class=" header-img " src="../assets/header_art_blob_brown.png">
                <v-img class=" elementos2 header-shadow" src="../assets/shadow.png"></v-img> 
                </v-img>
                <v-img class=" header-text" src="../assets/header_art_text_memoria.png"></v-img>
               
              </div>
            </v-col>-->


        </v-row>

        <v-row no-gutters class="align-center justify-center pb-15" style="margin-top: 10%">
          <v-col cols="16" class="text-center">
            <GameFrame
              url="https://freegame.probrain.com.br/exports/supermercado/"
              size="60vh"
              sizem="40vh"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="align-center justify-center banner"
          style="background-color: #5b2075"
        >
          <v-img
            src="../assets/Banner-freegame.png"
            style="width: 100%"
            @click="goTo('https://novo.afinandoocerebro.com.br/login/cadastro')"
          ></v-img>
        </v-row>
        <!--
   <v-row
          no-gutters
          class="align-center justify-center"
          style="background-color: #735023"
        >
          <v-col cols="10" class="text-center">
            <CTA
              url="https://novo.afinandoocerebro.com.br/login/cadastro"
              img="destaque.jpg"
            />
          </v-col>
        </v-row>
-->
      </v-card>
    </v-container>
    <Footer />
  </v-main>
</template>

<script>
import GameFrame from "./GameFrame.vue";
// import CTA from "./CTA.vue";
import Footer from "./FooterModule1.vue";

export default {
  name: "App",
  components: {
    GameFrame,
    Footer,
  },
  methods: {
    goTo: function (link) {
      console.log(link);
      window.location.href = link;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #00000;
}

.bg {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;
  background-color: #f0f0f0;
  animation: scroll 30s ease-in-out infinite alternate;
}

.banner {
  background: #5b2075;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  
}

.wrapper {
  height: auto;
  width: 100%;
  position: relative !important;
}

.cta {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  transform: scale(0.8);
  transform-origin: top;
}


.headerArts {
  transform: scale(0.8);
  margin-top: 15%;
  margin-bottom: -2%;
  transform-origin: bottom;
}

.elementos {
    transform: scale(1.1) !important;
    width: 100%;
    position: absolute;
    transform-origin: top;
   /* margin-left: ; */

    top: 0;
}

.zindex{
  z-index: 999 !important;
  position: absolute;
}

/*.elementos img{
  width: 100%;
} */

.elementos2 {
    position: absolute;
    top:0;
    width: 100%;  
} 

/*.texto {
  width: 40%;
  text-align: left;
  position: absolute;
  left: 28%;
  top: 90px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 32px;
}
*/
/*.title1 {
  color: rgb(255, 172, 46);
  font-weight: bold;
}
*/
/*.title2 {
  font-weight: lighter;
  color: #fff;
  font-size: 30px;
}
*/
/*span {
  color: rgb(255, 172, 46);
  font-weight: bold;
}
*/
.register {
  z-index: 999;
  cursor:pointer;
}
.move {
  
  animation: playing 2.5s ease-in infinite alternate both;
  -webkit-animation: playing 2.5s ease-in-out infinite alternate both;
  /* transform: scale(0.8) !important; */
}

@media (min-width: 1900px) {
  .texto {
    top: 90px;
    font-size: 25px;
  }
  .title1 {
    padding-bottom: 10px;
  }
  .title2 {
    font-size: 36px;
    line-height: 45px;
  }
}

@media (max-width: 1890px) {
  .texto {
    top: 60px;
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  .cta {
    height: 1px;
  }
}

@media (max-width: 1024px) {
  .texto {
    top: 50px;
    font-size: 10px;
  }
  .title2 {
    font-size: 16px;
    line-height: 20px;
  }
  div.cta {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .texto {
    top: 40px;
    font-size: 10px;
  }
  .title2 {
    font-size: 16px;
    line-height: 20px;
  }
  div.cta {
    padding-bottom: 0;
  }
}

@media (max-width: 425px) {
  .texto {
    width: 100%;
    top: 10px;
    font-size: 5px;
  }
  .title2 {
    font-size: 10px;
    line-height: 8px;
  }
}

@media (max-width: 375px) {
  .texto {
    top: 2px;
    font-size: 5px;
  }
  .title2 {
    font-size: 7px;
    line-height: 8px;
  }
}

@media (max-width: 320px) {
  .texto {
    top: 2px;
    font-size: 3px;
  }
  .title2 {
    font-size: 5px;
    line-height: 8px;
  }
}

@keyframes scroll {
  100% {
    background-position: 100px -500px;
  }
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.header-title {
  background-image: url("../assets/header_art_bg.png");
  background-size: contain;
}
.header-art {
  position: relative;
  width: 100%;
  animation: playing 2.5s ease-in infinite alternate both;
  -webkit-animation: playing 2.5s ease-in-out infinite alternate both;
}
.header-img {
  position: absolute !important;
  top: 0;
  right: 0;
  animation: levitating 1.5s ease-in-out infinite alternate both;
  -webkit-animation: levitating 1.5s ease-in-out infinite alternate both;
}

.header-text {
  position: absolute !important;
  top: 0;
  right: 0;
  animation: levitating-text 1.5s ease-in-out infinite alternate both;
  -webkit-animation: levitating-text 1.5s ease-in-out infinite alternate both;
}

.header-shadow {
  position: absolute !important;
  
  margin-top:20% ;
  margin-left:30%;
  transform-origin: center; 
  animation: scale 1.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: scale 1.5s ease-in-out infinite alternate-reverse both;
}

.brick-bg {
  background-image: url("../assets/bricks.jpg");
  background-size: contain;
  
}


.g-gradient-1 {
 /* background: rgb(160, 215, 225); */
  background: -moz-linear-gradient(360deg, #fedd6a 0%, #fedd6a 100%);
  background: -webkit-linear-gradient(360deg, #fedd6a 0%, #fedd6a 010%);
  background: linear-gradient(360deg, #fedd6a 0%, #fedd6a 100%);
 /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c0a284",endColorstr="#c0a284",GradientType=1); */
  
}
.g_page {
  background-color: #fafafa !important;
  /* min-height: 100vh; */
}

.afinando-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
/* Animations */

@-webkit-keyframes levitating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes levitating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@-webkit-keyframes levitating-text {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes levitating-text {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.1);

    transform: scale(0.1);
  }
  100% {
    
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(0.2);

    transform: scale(0.2);
  }
  100% {
    
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
}
@keyframes playing {
  0% {
    -webkit-transform: scale(0.8) rotate(0.5deg);
    transform: scale(0.8) rotate(0.5deg) ;
    

  }
  50% {
    -webkit-transform: scale(0.8) rotate(0deg);
    transform: scale(0.8) rotate(0.0deg) ;
    
  }
  100% {
    -webkit-transform: scale(0.8) rotate(-0.5deg);
    transform: scale(0.8) rotate(-0.5deg) ;
   
  }
}
</style>
