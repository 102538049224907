<template>
  <v-footer inset width="100%" padless>
    <v-card flat width="100%" >
      <v-card-text flat>
        <v-container fluid>
          <v-row align="center" justify="center" no-gutters>
            <v-col align="center" justify="center" cols="12">
              <v-img
                max-height="25"
                contain
                src="../assets/logo_afinando_b.png"
                
              ></v-img>
              <!-- &copy;{{ new Date().getFullYear() }} — -->
              <em >©2022 — Probrain Soluções Neurotecnológicas para Saúde e Educação Ltda | CNPJ 32.841.596/0001-49 <br />
Rua Eugênio Rabello, 98 - Jardim Embaixador, Sorocaba - SP, 18040-436 | atendimento@probrain.com.br</em>
            </v-col>
            <v-col
              align="center"
              justify="center"
              cols="12"
              class="quaternary_text pt-1"
            >
              <!-- <a class="quaternary_text" @click="terms_use = true"
                >Termos de uso</a
              > -->
              <v-divider vertical class="mx-1"></v-divider>
              <a
                class="quaternary_text"
                style="text-decoration: none"
                target="_blank"
                href="https://www.probrain.com.br/"
                >Empresa</a
              >
              <v-divider vertical class="mx-1"></v-divider>
              <!-- <a class="quaternary_text" @click="callContact()"
                >Precisa de Ajuda?</a
              > -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <!-- <v-dialog max-width="750" v-model="terms_use">
      <v-card>
        <v-toolbar card class="tertiary_bg">
          <v-toolbar-title>{{ terms_use_template.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="text-xs-right"
            flat
            icon
            @click.native="terms_use = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p v-html="terms_use_template.descricao"></p>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </v-footer>

</template>
<script>


export default {
  name: "FooterModule1",
  props: ["data"],

  // data() {
  
    // var objReturn = {};
    // objReturn.defaultx = language.defaultx;
    // objReturn.nick = "";
    // objReturn.copyright = language.copyright;
    // objReturn.fixed = true;
    // objReturn.terms_use_template = language.terms_use_template;
    // objReturn.terms_use_value = false;
    // objReturn.terms_use = false;

    // return objReturn;
  // },
  // methods: {
  //   callContact() {
  //     this.$emit("contact_support", undefined);
  //   },
  // },
  // mounted() {
  //   this.$bus.$on("show_terms", (show) => {
  //     this.terms_use = show;
  //   });
  // },
 };
</script>
<style scoped>
.set-self-ft {
  /*background-color: #EBF2FA !important ;*/
  border-top: 0.09em solid #727373 !important;
  z-index: 2 !important;
}
.set-self-ft img {
  max-width: 160px;
}
</style>