<template>
  <v-container fluid style="margin-top:-2%">
    <v-row no-gutters class="align-center justify-center" >
      <v-col cols="12" sm="9">
        <v-card outlined color="transparent" :class="returnClass($vuetify.breakpoint)" vuetify shaped >
        <!--v-card outlined color="transparent" :style="($vuetify.breakpoint.smAndDown) ? 'height:'+sizem : 'height:'+size" vuetify shaped -->
          <div class="g_iframe">
          <iframe
            :src="url"
            class="g_frame_border"
            frameborder="0"
            allowfullscreen
            
          ></iframe>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GameFrame",
  props: {
    url: String,
    size: String,
    sizem: String,
  },
  methods: {
    returnClass: function (breakpoint) {
      console.log(breakpoint);
      console.log("batata");

      if(breakpoint.xs){
        return "gameFrameXs"
      }
      if(breakpoint.sm){
        return "gameFrameSm"
      }
      if(breakpoint.md){
        return "gameFrameMd"
      }
      if(breakpoint.lg){
        return "gameFrameLg"
      }
      if(breakpoint.xl){
        return "gameFrameXl"
      }
    //     switch (breakpoint) {
    //     case "xs":
    //         return "gameFrameXs";
    //         //break;
    //     case "sm":
    //         return "gameFrameSm";
    //         //break;
    //     case "md":
    //         return "gameFrameMd";
    //         //break;
    //    case "lg":
    //         return "gameFrameLg";
    //        // break;
    //     default:
    //         return "gameFrameXl";
    // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.gameFrameXs{
  height: 200px !important;
  width: 300px;
  margin: 0 auto;
}
.gameFrameSm{
  height: 280px !important;
  width: 500px;
  margin: 0 auto;
}
.gameFrameMd{ 
  height: 350px !important;
  width: 700px;
}
.gameFrameLg{
  height: 500px !important;
  width: 1000px;
}
.gameFrameXl{
  height: 650px !important;
  width: 1200px;
}
.g_frame_border{
  border-radius: 24px 4px;
}
.g_iframe iframe {
  
  width: 100% !important;
  height: 100% !important;
}
.g_iframe {
 
  height: 100% !important;
  width: 100% !important;
}
</style>
